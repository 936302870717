import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
  ViewContainerRef
} from '@angular/core'
import { EmojiSelectorComponent } from '../components/utils/emoji-selector/emoji-selector.component'
import { EditorComponent } from '../components/utils/editor/editor.component'

@Directive({
  selector: '[appEmojiSelector]',
  standalone: true
})
export class EmojiSelectorDirective implements OnDestroy {
  static lastOpenEmojiSelectorDirective?: EmojiSelectorDirective

  emojiSelectorComponent?: ComponentRef<EmojiSelectorComponent>
  emojiSelectorDisplay = false
  closeOnScroll = false

  @Input({ required: true })
  editorOwner!: EditorComponent

  removeScrollListener?: () => void

  constructor(
    private element: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2
  ) {}

  ngOnDestroy(): void {
    this.removeScrollListener?.()
    this.destroySelector()
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (!this.emojiSelectorDisplay) return
    if (this.emojiSelectorComponent === undefined) return

    const clickedInside =
      this.emojiSelectorComponent.location.nativeElement.contains(event.target)
    if (event.target !== null) {
      const instance = EditorComponent.getEditorInstanceByTarget(event.target)
      if (instance !== null) {
        // Cambiamos el owner del selector
        this.editorOwner.unmarkAsOwnedByEmojiSelector()
        this.editorOwner = instance
        this.editorOwner.markAsOwnedByEmojiSelector()
        return
      }
    }
    if (!clickedInside && !this.element.nativeElement.contains(event.target)) {
      this.destroySelector()
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (event.key === 'Escape') {
      if (this.emojiSelectorDisplay) {
        this.destroySelector()
      }
    }
  }

  @HostListener('click', ['$event'])
  onClick() {
    this.emojiSelectorDisplay = !this.emojiSelectorDisplay
    if (this.emojiSelectorDisplay) {
      this.createSelector()
    } else {
      this.destroySelector()
    }
  }

  setupRightPaneScrollListener() {
    let elementToListen = document.querySelector('div.right-pane')
    if (elementToListen === null) {
      elementToListen = document.body
    }
    this.removeScrollListener = this.renderer.listen(
      elementToListen,
      'scroll',
      this.onScroll.bind(this)
    )
  }

  onScroll() {
    if (!this.closeOnScroll) return
    if (this.emojiSelectorDisplay) {
      this.emojiSelectorDisplay = false
      // AL hacer scroll removemos el componente (lo cerramos)
      EmojiSelectorDirective.lastOpenEmojiSelectorDirective?.destroySelector()
      EmojiSelectorDirective.lastOpenEmojiSelectorDirective = undefined
      this.removeScrollListener?.()
    }
  }

  createSelector() {
    this.removeScrollListener?.()
    this.setupRightPaneScrollListener()
    EmojiSelectorDirective.lastOpenEmojiSelectorDirective?.destroySelector()
    this.emojiSelectorDisplay = true
    this.emojiSelectorComponent = this.viewContainerRef.createComponent(
      EmojiSelectorComponent
    )
    EmojiSelectorDirective.lastOpenEmojiSelectorDirective = this
    const host = document.body
    host.insertBefore(
      this.emojiSelectorComponent.location.nativeElement,
      host.firstChild
    )

    this.emojiSelectorComponent.instance.directive = this
    this.emojiSelectorComponent.instance.anchorRef = this.element
    this.emojiSelectorComponent.instance.alignToAnchor()
    this.editorOwner.markAsOwnedByEmojiSelector()
  }

  destroySelector() {
    this.removeScrollListener?.()
    this.editorOwner.unmarkAsOwnedByEmojiSelector()
    this.emojiSelectorDisplay = false
    if (this.emojiSelectorComponent === undefined) return
    this.emojiSelectorComponent.destroy()
    try {
      document.body.removeChild(
        this.emojiSelectorComponent.location.nativeElement
      )
    } catch (ex: unknown) {
      // ignore
    }
  }

  getOwner() {
    return this.editorOwner
  }
}
